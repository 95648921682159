import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { rgba } from "polished";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";
import { device } from "../utils";

import CaseList from "../sections/landing1/Testimonial";
import CTA from "../sections/case-study/CTA";
// @ts-expect-error false positive image import failure
import imgBrand from "../assets/image/case-studies/kingdom-logo.png";
// @ts-expect-error false positive image import failure
import bgImage from "../assets/image/case-studies/kingdom-hero.jpeg";
// @ts-expect-error false positive image import failure
import imgCase2 from "../assets/image/jpeg/case-details-half-single.jpg";
// @ts-expect-error false positive image import failure
import imgCase3 from "../assets/image/jpeg/case-details-half-single-2.jpg";

const Quotes = styled.span`
  font-size: 3rem;
  font-weight: 700;
  margin: 0 10px;
  color: #f04037;
`;

const Header = styled(Title)`
  font-size: 4.5rem;
`;
const Quote = styled(Text)`
  font-size: 1.5rem;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
`;

const ULStyled = styled.ul`
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding-left: 0;
  margin-bottom: 10px;

  @media ${device.sm} {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #021d2d;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 25px;
      height: 25px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 0.8rem;
      margin-right: 0.2rem;
    }
  }
`;

const CaseStudyDetails = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Helmet>
          <title>Kingdom Shopping Centre Case Study</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Section
          backgroundImage={`url(${bgImage})`}
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          position="relative"
        >
          <Overlay />
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box className="text-center" mb={4}>
                  <img src={imgBrand} alt="" className="img-fluid" />
                </Box>
                <Header variant="hero">Kingdom Shopping Centre</Header>
                <Quote>
                  <Quotes>"</Quotes>When we learned that we could roll two
                  systems up into one, we were sold.<Quotes>"</Quotes>
                </Quote>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="position-relative">
          <Container>
            <Row>
              <Col lg="12" xl="10" className="offset-xl-1">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    Overview
                  </Title>
                  <Text variant="small">
                    Kingdom Shopping Centre in Glenrothes, fife Gets a Two For
                    One system - DMR digital radio and an rfid powered tour
                    guarding solution in one. Replacing two aging systems with a
                    singular unified solution, boosting productivity and cutting
                    costs at the same time!
                  </Text>
                </Box>
                <Box>
                  <Title variant="card" mb="18px">
                    About the Client
                  </Title>
                  <Text variant="small">
                    Located in the famous whiskey town of Glenrothes, Kingdom
                    Shopping Centre is the largest shopping centre in Fife,
                    east-central Scotland. It is about 30 miles north of
                    Edinburgh and 30 miles south of Dundee.
                    <br />
                    <br />
                    The majority of shopping, retail services and administrative
                    facilities in Glenrothes are concentrated in the town
                    centre. With approximately 120 shop units, the Kingdom
                    Centre provides the largest concentration of shops in the
                    town centre.
                  </Text>
                </Box>
              </Col>
            </Row>
            {/* <Row>
              <Col lg="6" className="mb-lg-0 mb-30">
                <Box>
                  <img src={imgCase2} alt="" className="img-fluid" />
                </Box>
              </Col>
              <Col lg="6">
                <Box>
                  <img src={imgCase3} alt="" className="img-fluid" />
                </Box>
              </Col>
            </Row> */}
          </Container>
        </Section>
        <Section bg="#f6f6f8" py={0}>
          <Container>
            <Row className="pt-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    The Challenges
                  </Title>
                  <ULStyled>
                    <li>Big Point 1</li>
                    <li>Big Point 2</li>
                    <li>Big Point 3</li>
                  </ULStyled>
                  <Text variant="small" mb={3}>
                    The centre had maintained two systems in parallel for many
                    years. Firstly, an estate of analogue radios were being used
                    across the centre by security & housekeeping teams. They
                    were suffering from poor microphones and short battery life.
                    The cost of repairs and new batteries was escalating as the
                    radios approached the end of their shelf life.
                    <br />
                    <br />
                    Secondly, the centre used a separate patrol guard system
                    with a set of electronic wands that were used by security
                    and housekeeping to record their whereabouts during the day.
                    These wands were also approaching the end of their term, and
                    were beginning to wear out.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section py={1}>
          <Container>
            <Row className="mt-5 mb-5 pb-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box>
                  <Title variant="card" mb="18px">
                    The Solution
                  </Title>
                  <Text variant="small">
                    In late 2016, the shopping centre contacted ShopSafe, a
                    national Hytera dealer with offices in England and Scotland.
                    ShopSafe recommended replacing both systems with a singular
                    system and proposed the Hytera PD415 digital radios with
                    Patrol. This means that instead of using electronic wands to
                    record their whereabouts, team members could use the radios
                    themselves thanks to Hytera’s RFID tagging technology.
                    <br />
                    <br />
                    Staff members were issued with a personal ID tag, meaning
                    that when they were issued with a radio, they could be
                    identified as the user of that specific radio.
                    <br />
                    <br />
                    Security and Housekeeping teams have to record their
                    whereabouts every 30 minutes using a list of locations. The
                    new patrol guard system ensures that their location is
                    registered but also that each interaction with a checkpoint
                    is logged against an individual, which was not possible with
                    the previous system. This information can be displayed on a
                    stand-alone monitor in the control room which shows all
                    checkpoint locations. ShopSafe were selected to deliver a
                    common digital radio network across all the towns. Delivered
                    over the same IP network as the CCTV cameras, both CCTV
                    operators and their manager have access to all the towns
                    where cameras are monitored. ShopSafe delivered a common DMR
                    digital network using a range of subscriber products from
                    Hytera. The responsibility to migrate and support the
                    schemes was solely ShopSafe’s. This includes billing to
                    deliver the goal of income generation to offset the running
                    costs for the Council.
                    <br />
                    <br />
                    In partnership with the maintenance team, ShopSafe installed
                    the system throughout the shopping centre and supported the
                    teams by training them how to use their new radios.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bg="#f6f6f8" py={0}>
          <Container>
            <Row className="pt-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    The Results
                  </Title>
                  <ULStyled>
                    <li>Big Point 1</li>
                    <li>Big Point 2</li>
                    <li>Big Point 3</li>
                  </ULStyled>
                  <Text variant="small">
                    Initial feedback is that the handsets are light, yet rugged,
                    and are extremely user-friendly.
                    <br />
                    <br />
                    However, the biggest upside to the business is that they no
                    longer have to maintain two independent systems. That brings
                    about a cost saving, but more importantly, it means that
                    individuals are tagged to the checkpoint location, adding a
                    layer of data for due diligence purposes that they didn’t
                    have before, as the tag and the radio go together. This
                    helps the centre demonstrate safety, and acts as a record
                    for insurance purposes.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <CaseList />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CaseStudyDetails;
